import axios from 'axios';
import Cookies from 'js-cookie';
// const { REACT_APP_API_URL } = process.env;
import { apiBaseURL } from '_helpers';

const fetchWrapper = axios.create({
  baseURL: apiBaseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

fetchWrapper.interceptors.request.use(async config => {
  let tokensData = await JSON.parse(Cookies.get('tokens') || '{}');
  //config.headers.common["Authorization"] = `bearer ${tokensData.accessToken}`;
  config.headers['Authorization'] = 'Bearer ' + tokensData.accessToken;
  return config;
});

fetchWrapper.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    //const config = error?.config;
    const originalConfig = error.config;
    //const originalConfig = err.config;
    if (error.response.status === 401 && !originalConfig._retry) {
      // && !originalConfig._retry

      originalConfig._retry = true;
      //originalConfig._retry = true;
      const authData = JSON.parse(Cookies.get('tokens'));
      const payload = {
        accessToken: authData.accessToken,
        refreshToken: authData.refreshToken
      };

      let apiResponse = await axios.post(
        `${apiBaseURL}/auth/api/authenticate/refresh-token`,
        payload
      );

      Cookies.set('tokens', JSON.stringify(apiResponse.data));
      originalConfig.headers.Authorization = `Bearer ${apiResponse.data.accessToken}`;
      // error.config.headers[
      //   "Authorization"
      // ] = `bearer ${apiResponse.data.accessToken}`;

      return axios(originalConfig);
    } else {
      return Promise.reject(error);
    }
  }
);

export default fetchWrapper;
